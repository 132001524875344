<template>
  <div>
    <table-spinner :loading="pageLoading"></table-spinner>
    <div v-if="!pageLoading">
      <b-card no-body class="mb-0 p-1">
        <template #header></template>
        <profile-form v-if="user" :user="user" @save="onSaveUser" />
        <br />
        <subscription-information :user="user" :hide-upgrade-services="true" />
        <div v-if="user" class="p-2">
          <b-button
            class="action-buttons cols col-sm-12 col-md-4 col-lg-3"
            variant="outline-primary"
            @click="onLoginAsUser"
          >
            Log in as
          </b-button>
          <b-button
            variant="outline-primary"
            class="action-buttons cols col-sm-12 col-md-4 col-lg-3"
            @click="onEmailVerify"
            v-if="!user.is_email_verified"
            >Email verify
          </b-button>
          <b-button
            variant="outline-primary"
            class="action-buttons cols col-sm-12 col-md-4 col-lg-3"
            @click="onSendPasswordReset"
          >
            Send password reset email
          </b-button>
          <b-button
            variant="outline-primary"
            class="action-buttons cols col-sm-12 col-md-4 col-lg-3"
            v-if="authUser.id !== user.id"
            @click="onBan(!user.is_banned)"
            :disabled="loading"
          >
            {{ user.is_banned ? "Remove ban" : "Ban" }}
            <b-spinner
              v-if="loading"
              small
              type="grow"
              class="m-auto"
            ></b-spinner>
          </b-button>
          <b-button
            variant="outline-primary"
            class="action-buttons cols col-sm-12 col-md-4 col-lg-3"
            v-if="authUser.id !== user.id"
            @click="onInactivate(!user.is_inactivated)"
            :disabled="loading"
          >
            {{ user.is_inactivated ? "Activate" : "Inactivate" }}
            <b-spinner
              v-if="loading"
              small
              type="grow"
              class="m-auto"
            ></b-spinner>
          </b-button>
          <b-button
            variant="outline-primary"
            class="action-buttons cols col-sm-12 col-md-4 col-lg-3"
            v-if="authUser.id !== user.id"
            @click="onRegenerateLink()"
            :disabled="loading"
          >
            Regenerate Link
            <b-spinner
              v-if="loading"
              small
              type="grow"
              class="m-auto"
            ></b-spinner>
          </b-button>
        </div>
        <user-ip-table
          :data="ips"
          :fields="fields"
          :selected="selected"
          :loading="loading"
          @rowSelected="onRowSelected"
          @deleteSelected="deleteSelected"
          @changePage="changePage($event)"
          @changePerPage="changePerPage({ per_page: $event.per_page })"
          :meta="meta"
          :filter="filter"
        ></user-ip-table>
        <user-service-list :userServices="userServices"></user-service-list>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BButton, BCard, BSpinner } from "bootstrap-vue";
import { mapActions } from "vuex";
import { fetchUser } from "@/services/api/admin";
import ProfileForm from "@/layouts/components/user/ProfileForm.vue";
import { apiErrorHandler } from "@/services/utils/util";
import SubscriptionInformation from "@/layouts/components/user/SubscriptionInformation.vue";
import TableSpinner from "@/layouts/components/TableSpinner.vue";
import UserServiceList from "@/layouts/components/user/UserServiceList.vue";
import UserIpTable from "@/layouts/components/user/UserIpTable.vue";
import { getUserServicesApi } from "@/services/api/auth";
import debounce from "lodash.debounce";
import { onUserBanToggle, onUserInactivateToggle } from "@/services/utils/util";
import Vue from "vue";

export default {
  components: {
    BCard,
    ProfileForm,
    UserServiceList,
    UserIpTable,
    BButton,
    BSpinner,
    TableSpinner,
    SubscriptionInformation,
  },
  data() {
    return {
      user: null,
      id: this.$route.params.id,
      loading: false,
      pageLoading: false,
      userServices: null,
      selected: [],
      fields: [
        { key: "selected", label: "Selected" },
        { key: "ip", label: "IP" },
        { key: "created_at", label: "Created at" },
        { key: "updated_at", label: "Updated at" },
      ],
      filter: {
        page: 1,
        per_page: 10,
        sort_by: "updated_at",
        order_by: "asc",
      },
    };
  },
  computed: {
    authUser() {
      return this.$store.getters["auth/authUser"];
    },
    ips() {
      return this.$store.getters["adminUser/getUserIps"];
    },
    meta() {
      return this.$store.getters["adminUser/getUserListMeta"];
    },
  },
  mounted() {
    this.onFetchUserData();
    this.getUserServices();
  },
  created() {
    this.$watch(
      () => this.$route.params.id,
      (id) => {
        this.id = id;
        this.onFetchUserData();
      }
    );
  },
  methods: {
    ...mapActions({
      updateUsers: "adminUser/updateUsers",
      getUserIps: "adminUser/getUserIps",
      deleteUserIps: "adminUser/deleteUserIps",
      banUserToggle: "adminUser/banUserToggle",
      inactivateUserToggle: "adminUser/inactivateUserToggle",
      verifyUserEmail: "adminUser/verifyUserEmail",
      forgetPassword: "adminUser/forgetPassword",
      loginAs: "auth/loginAs",
      regenerateLink: "adminUser/regenerateLink",
    }),
    changePage(filter = {}) {
      Object.assign(this.filter, filter);
      console.log(this.filter);
      this.onRefreshIpList();
    },
    changePerPage(filter = {}) {
      this.filter.page = 1;
      this.filter.per_page = filter.per_page;
      this.onRefreshIpList();
    },
    async onFetchUserData() {
      this.pageLoading = true;
      try {
        const user = await fetchUser(this.id);
        this.user = user;
      } catch (e) {
        apiErrorHandler(e);
      } finally {
        this.pageLoading = false;
      }
    },
    async onBan(checked) {
      const user = this.user;
      try {
        await onUserBanToggle(checked, user, this.banUserToggle);
      } finally {
        await this.onFetchUserData();
      }
    },
    async onInactivate(checked) {
      const user = this.user;
      try {
        await onUserInactivateToggle(checked, user, this.inactivateUserToggle);
      } finally {
        await this.onFetchUserData();
      }
    },
    async onRegenerateLink() {
      const user = this.user;
      try {
        await this.regenerateLink({ id: user.id });
      } finally {
        await this.onFetchUserData();
      }
    },
    async onSaveUser(user) {
      try {
        await this.updateUsers(user);
        if (user.epg_timezone !== this.user.epg_timezone) {
          Vue.$toast.info(
            "Regenerating the EPG XML link may take several minutes after changing the time zone."
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        await this.onFetchUserData();
      }
    },
    async onLoginAsUser() {
      await this.loginAs(this.user);
    },
    async onEmailVerify() {
      await this.verifyUserEmail(this.user);
    },
    async onSendPasswordReset() {
      await this.forgetPassword(this.user);
    },
    async getUserServices() {
      try {
        const services = await getUserServicesApi(this.id);
        this.userServices = services;
      } catch (e) {
        apiErrorHandler(e);
      }
    },
    async deleteSelected() {
      const selectedIps = this.selected.map((element) => element.ip);

      this.loading = true;
      try {
        await this.deleteUserIps({ id: this.id, ips: selectedIps });
        this.onRefreshIpList();
      } finally {
        this.selected = [];
        this.loading = false;
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    onRefreshIpList: debounce(async function () {
      this.loading = true;
      try {
        await this.getUserIps({ id: this.id, ...this.filter });
      } finally {
        this.loading = false;
      }
    }, 300),
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.profile-form {
  flex: 0 0 100%;
  max-width: 100%;
}
.action-buttons {
  margin: 10px;
}
@media only screen and (max-width: 768px) {
  .action-buttons {
    margin: 10px auto 0 auto;
  }
}
</style>
