<template>
  <div>
    <b-card no-body class="mb-0">
      <template #header>
        <b-button
          v-if="selected.length !== 0"
          :disabled="loading"
          size="sm"
          variant="primary"
          class="ml-1"
          @click="$emit('deleteSelected')"
        >
          Delete selected
        </b-button>
      </template>
      <b-table
        selectable
        select-mode="multi"
        @row-selected="$emit('rowSelected', $event)"
        id="user-ips-list"
        class="mt-1"
        :items="data"
        responsive
        primary-key="id"
        :fields="fields"
        show-empty
        empty-text="No matching records found"
      >
        <template #head()="fields">
          <table-header-labels
            :fields="fields"
            :filter="filter"
            @onSort="$emit('changePage', $event)"
          ></table-header-labels>
        </template>
        <template #cell(selected)="{ rowSelected }">
          <template>
            <b-form-checkbox disabled :checked="rowSelected"></b-form-checkbox>
          </template>
        </template>
        <template #cell(is_server)="data">
          <b-form-checkbox disabled :checked="data.item.is_server" />
        </template>
        <template #cell(created_at)="data">
          {{ $dateHelpers.formatDate(data.item.created_at) }}
          {{ $dateHelpers.formatTime(data.item.created_at) }}
        </template>
        <template #cell(updated_at)="data">
          {{ $dateHelpers.formatDate(data.item.updated_at) }}
          {{ $dateHelpers.formatTime(data.item.updated_at) }}
        </template>
      </b-table>
      <template #footer>
        <pagination
          :meta-data="meta"
          @change-page="$emit('changePage', { page: $event })"
          @change-per-page="$emit('changePerPage', { per_page: $event })"
        >
        </pagination>
      </template>
    </b-card>
  </div>
</template>

<script>
import { BTable, BCard, BButton, BFormCheckbox } from "bootstrap-vue";
import Pagination from "@/layouts/components/XmlTvPagination.vue";
import TableHeaderLabels from "@/layouts/components/TableHeaderLabels.vue";

export default {
  name: "UserIpTable",
  components: {
    BTable,
    BCard,
    BButton,
    BFormCheckbox,
    Pagination,
    TableHeaderLabels,
  },
  props: {
    data: {
      type: Array,
    },
    fields: {
      type: Array,
    },
    selected: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    meta: {
      type: Object,
    },
    filter: {
      type: Object,
    },
  },
};
</script>

<style></style>
